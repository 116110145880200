import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import ReactECharts from 'echarts-for-react'
import axios from 'axios'
import { createBarChart, createPieChart } from '../../utils/chartStyles'
// markup
import gavlePlacesStrings from '../../utils/gavle-places'
import './dashboards.scss'

const URL = 'https://connect.consupedia.com/api/v1/dashboard/gavle'

function sortByValue(a, b) {
  return a.value > b.value ? 1 : -1
}

const Gavle = () => {
  const [co2Total, setCo2Total] = useState(0)
  const [co2TotalVikt, setCo2TotalVikt] = useState(0)
  const [co2Count, setCo2Count] = useState(0)
  const [consCats, setConsCats] = useState([])
  const [gavleCats, setGavleCats] = useState([])
  const [gavlePlaces, setGavlePlaces] = useState([])

  useEffect(() => {
    // 1. Read chart data from API
    axios
      .get(URL)
      .then((data) => {
        const {
          Co2Count,
          Co2Total,
          Co2TotalVikt,
          category_totals,
          custom1_totals,
          custom2_totals
        } = data.data.data

        // console.log('data', data.data.data)

        setCo2Count(Co2Count)
        setCo2Total(Co2Total)
        setCo2TotalVikt(Co2TotalVikt)

        setGavleCats(
          custom1_totals.sort(sortByValue).map((a) => ({
            ...a,
            // value: Math.round((a.value / Co2TotalVikt) * 10000) / 100,
            value: Math.round(a.value)
          }))
        )

        setGavlePlaces(
          custom2_totals.sort(sortByValue).map((a) => ({
            ...a,
            name: gavlePlacesStrings[a.name],
            // value: Math.round((a.value / Co2TotalVikt) * 10000) / 100,
            value: Math.round(a.value)
          }))
        )
        setConsCats(
          category_totals.sort(sortByValue).map((a) => ({
            ...a,
            // value: Math.round((a.value / Co2TotalVikt) * 10000) / 100,
            value: Math.round(a.value)
          }))
        )
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [])

  const options_cats = createPieChart(
    'CO2e per kategori',
    consCats,
    null,
    co2TotalVikt
  )
  const options_gavleCats = createPieChart(
    'Topp 10 produktgrupper',
    gavleCats,
    10,
    co2TotalVikt
  )
  const options_gavlePlacesMeanTop10 = createBarChart(
    '10 högst snitt CO2e per produkt',
    gavlePlaces,
    35,
    (a) => ({
      name: a.name,
      value: Math.round((a['co2'] / a.count) * 100) / 100
    }),
    10
  )
  const options_gavlePlacesMeanWorst10 = createBarChart(
    '10 lägst snitt CO2e per produkt',
    gavlePlaces,
    35,
    (a) => ({
      name: a.name,
      value: Math.round((a['co2'] / a.count) * 100) / 100
    }),
    10,
    true
  )
  const options_gavlePlacesTotal = createBarChart(
    'Topp 20 total CO2e kök',
    gavlePlaces,
    35,
    (a) => a,
    20
  )
  const options_gavlePlaces = createBarChart(
    'Topp 20 total CO2e kök',
    gavlePlaces,
    35
  )
  // const options = createPieChart('test', )

  // Category mean product CO2
  const mean_co2_cats = createBarChart(
    'CO2e snitt per produkt & kategori',
    consCats,
    30
  )

  return (
    <Layout constricted>
      <title>Sustainability Dashboard - Consupedia</title>
      <div className="hero dark-bg alignfull dashboard-header">
        <div className="content-container">
          <div>
            <h1>Gävle</h1>
            <h3>Första halvåret 2022</h3>
            <br />
            Totalt: <strong>{Math.round(co2TotalVikt / 10) / 100}</strong> Ton
            CO2ekv <br />
            Medelvärde produkt:{' '}
            <strong>{Math.round((co2Total / co2Count) * 100) / 100}</strong> kg
            CO2ekv / kg
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <section className="alignfull dashboard-content">
        <div className="section-container chart-container">
          <div className="cols">
            <div className="col">
              <div className="single-chart-container">
                <ReactECharts
                  option={mean_co2_cats}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
              <br />
            </div>
          </div>
          <div className="cols" style={{ marginBottom: '3rem' }}>
            <div className="col pie-col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_cats}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
            </div>
            <div className="col pie-col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_gavleCats}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
            </div>
          </div>

          <div className="cols">
            <div className="col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_gavlePlacesTotal}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
              <br />
            </div>
          </div>
          <div className="cols">
            <div className="col pie-col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_gavlePlacesMeanTop10}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
              <br />
            </div>
            <div className="col pie-col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_gavlePlacesMeanWorst10}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
              <br />
            </div>
          </div>
          {/* <div className="cols">
            <div className="col">
              <div className="single-chart-container">
                <ReactECharts
                  option={options_gavlePlaces}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}

                />
              </div>
              <br />
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default Gavle
