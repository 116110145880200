import colorPalette from './colorPalette'

const chartStyles = {
  textStyle: {
    fontFamily: '"tt_commonsbold", sans-serif',
    fontWeight: 'bold'
  }
}

const pieChartStyles = {}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
function sortByValue(a, b) {
  return a.value > b.value ? 1 : -1
}
const createBarChart = (
  title,
  data,
  rotate = 30,
  mapFunc = (a) => ({
    name: a.name,
    value: Math.round((a['co2'] / a.count) * 100) / 100
  }),
  max = null,
  reverse = false
) => {
  let data_ = [...data].map(mapFunc).sort(sortByValue)
  if (reverse) {
    data_ = data_.reverse()
  }
  if (max) {
    data_ = data_.slice(data_.length - max, data_.length)
  }
  return {
    ...chartStyles,
    title: {
      text: title,
      color: 'pink',
      subtext: '',
      left: 'left'
    },
    xAxis: {
      data: data_.map((a) => {
        let name = capitalizeFirstLetter(a.name.toLowerCase())
        if (name.length > 18) {
          name = name.substring(0, 15) + '...'
        }
        return name
      }),
      axisLabel: {
        interval: 0,
        rotate: 35 //If the label names are too long you can manage this by rotating the label.
      }
    },
    tooltip: {
      trigger: 'item'
    },
    yAxis: {},
    series: [
      {
        color: colorPalette(2),
        type: 'bar',
        data: data_
      }
    ]
  }
}

const createPieChart = (title, data, max = null, total = null) => {
  const data_ = data.map((a) => ({
    ...a,
    tooltip: {
      formatter: '{b} : {c} kg ({d}%)',
      trigger: 'item'
    }
  }))
  return {
    ...chartStyles,
    title: {
      text: title,
      color: 'pink',
      subtext: '',
      left: 'left'
    },
    plugins: {
      title: {
        color: 'pink'
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: total
        ? (name) => {
            const fixedName = name.replace(/\s{2,}/g, ' ').trim()
            let itemValue = data.filter((item) => item.name === name)
            let percent = Math.round((itemValue[0].value / total) * 10000) / 100
            return `${fixedName} ${percent}%`
          }
        : null
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      formatter: total
        ? (name) => {
            const fixedName = name.replace(/\s{2,}/g, ' ').trim()
            let itemValue = data.filter((item) => item.name === name)
            let percent = Math.round((itemValue[0].value / total) * 10000) / 100
            return `${fixedName} \n${percent}%`
          }
        : (name) => name.replace(/\s{2,}/g, ' ').trim()
    },
    series: [
      {
        type: 'pie',
        color: colorPalette(max || data.length),
        radius: '80%',
        center: ['40%', '50%'],
        data: max ? data_.reverse().slice(0, max).reverse() : data_,
        itemStyle: {
          normal: {
            left: 'left',
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          }
        }
      }
    ]
  }
}

export { chartStyles, pieChartStyles, createPieChart, createBarChart }
