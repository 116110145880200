const gavlePlacesStrings = {
  200019: 'GASKLOCKORNA GÄVLE',
  210511: 'LERGÖKEN',
  214306: 'POLHEMSSKOLAN RL MA',
  218090: 'HEMLINGBYSTUGAN',
  219970: 'VÄSTERBERGS FOLKHÖGSKOLA',
  223123: 'VILLA BRYNÄS',
  223132: 'OMV SELGGRENSGÅRD V3',
  223261: 'ÄNGSLYCKAN HUS B',
  223264: 'ÄNGSLYCKAN HUS C',
  224575: 'HERRGÅRDENS FSK',
  224764: 'FURUGÅRDEN AVD D',
  230671: 'TALLÅSGÅRDENS FSK',
  230674: 'GÄVLE STRANDS PLAN 1',
  233197: 'ÄNGSULLS FÖRSKOLA',
  234532: 'BOMHUS DAGVERKSAMHET',
  234961: 'ÖSTERS FÖRSKOLA',
  239047: 'NORRSKENSGÅRDENS FÖRSKOLA',
  243091: 'BRYNÄS DAGVERKSAMHET',
  243520: 'GÄVLE STRAND V O B ADMIN',
  244957: 'HOTELL & TURISMPROGRAMMET',
  245560: 'OMV FORELLPLAN 2A+2B',
  247774: 'VALLONGÅRDEN A-C',
  247777: 'VALLONGÅRDEN B-D',
  247783: 'VALLONGÅRDEN E-G',
  247789: 'VALLONGÅRDEN F-H',
  247792: 'VALLONGÅRDEN J-L',
  247795: 'VALLONGÅRDEN K-M',
  247807: 'VILLA BRYNÄS 8 B BERGMÄSTAREN',
  247828: 'FLEMMINGGATAN 13 GODSMOTTAGN',
  247831: 'SOLGÅRDSG.VÅRD&OMS.BO PL 4',
  247834: 'SOLGÅRDSG.VÅRD&OMS.BO PL 3',
  250441: 'VASASKOLAN CAFE',
  250498: 'RESTAURANGSKOLAN POLHEM',
  279410: 'SOLKATTENS FSK',
  279889: 'BÖNAN FSK',
  280739: 'POLHEMSSKOLAN RL',
  280879: 'MAJGÅRDENS FÖRSKOLA',
  280970: 'MILBOGÅRDENS FSK',
  281390: 'MYRBACKAGÅRDENS FSK',
  282190: 'NÄRBOGÅRDEN FSK',
  282430: 'OLSBACKAGÅRDEN FSK',
  282529: 'PINGELTORPS FSK',
  282570: 'POLHEMSSKOLANS CAFÉ',
  282669: 'STAFFANSGÅRDEN FSK',
  282719: 'TUMMELISAGÅRDEN FSK',
  282859: 'VALLBACKSGÅRD FSK',
  282909: 'VARVATERRASSENS FSK',
  283040: 'NYA LURBERGSGÅRD FSK',
  283370: 'TROLLGÅRDEN FSK',
  318886: 'FLEMINGGAT 11 SÄRSK.BO',
  319033: 'MASURGÅRDEN FSK',
  319072: 'ULVSÄTERSGÅRDEN FSK',
  319114: 'GULDSMEDENS FSK',
  319126: 'KÄLLÖ FÖRSKOLA',
  319144: 'SÖRBY FSK',
  319150: 'MÅRDVÄGENS FSK',
  319186: 'MARKHEDENS FSK',
  319204: 'SOLBERGAHEMMET ÄB',
  319231: 'APOLLOGÅRDEN FSK',
  319336: 'BORGARSKOLAN CAFE',
  319381: 'HÖVDINGAVÄGENS FSK',
  319390: 'HILLEBORG ÄB',
  319396: 'ALMGÅRDEN FSK',
  319408: 'SOFIEDALSGÅRDEN',
  319417: 'AME VARVA CAFE JÄGAR',
  319489: 'LINDBACKA FSK',
  319549: 'HÄCKLINGE FSK',
  319621: 'SÄTRA DAGVERKSAMHET',
  319639: 'STENEBERGSGÅRDEN FÖRSKOLA',
  320053: 'FRIDEBO FSK',
  320071: 'FYRENS FSK'
}

export default gavlePlacesStrings
